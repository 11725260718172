@import './colors.scss';

@mixin borderTopBefore($padding) {
	position: relative;
	padding-top: 12px;

	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: $padding;
		right: 0;
		bottom: 0;
		z-index: 1;
		border-top: 1px solid $light2;
	}
}

@mixin insetShadowBefore($radius:0) {
	position: relative;

	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 2;
		border: 1px solid #000000;
		opacity: 0.12;
		border-radius: $radius;
	}
}

@mixin elipsisMultiline($lines-to-show) {
	display: -webkit-box;
	-webkit-line-clamp: $lines-to-show;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

@mixin backgroundImageSet($image, $gradient:null) {
	@if ($gradient) {
		/*noinspection CssOverwrittenProperties*/
		background-image: url('/i/'+ $image +'.png'), $gradient;
		/*noinspection CssOverwrittenProperties*/
		background-image: image-set(
				url('/i/'+ $image +'.png') 1x,
				url('/i/'+ $image +'@2x.png') 2x,
				url('/i/'+ $image +'@3x.png') 3x
		), $gradient;
	} @else {
		/*noinspection CssOverwrittenProperties*/
		background-image: url('/i/'+ $image +'.png');
		/*noinspection CssOverwrittenProperties*/
		background-image: image-set(
				url('/i/'+ $image +'.png') 1x,
				url('/i/'+ $image +'@2x.png') 2x,
				url('/i/'+ $image +'@3x.png') 3x
		);
	}
}

@mixin backgroundImageSetIfrype($image) {
	/*noinspection CssOverwrittenProperties*/
	background-image: url('//ifrype.com/i/'+ $image +'.png');
	/*noinspection CssOverwrittenProperties*/
	background-image: image-set(
			url('//ifrype.com/i/'+ $image +'.png') 1x,
			url('//ifrype.com/i/'+ $image +'@2x.png') 2x,
			url('//ifrype.com/i/'+ $image +'@3x.png') 3x
	);
}

@mixin backgroundImageSvg($image, $gradient:null) {
	@include backgroundImageSet($image, $gradient);
	background-image: url('/i/'+ $image +'.svg');
}

@mixin backgroundCover() {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
}

@mixin backgroundContain() {
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center center;
}

@mixin maxLines($lines) {
	display: -webkit-box;
	-webkit-line-clamp: $lines;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

@mixin imageMask() {
	mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC); // atļauj apgriezt overflow hidden arī bildēm
}

@mixin smallCard() {
	border-top: 1px solid $light2;
	border-bottom: 1px solid $light2;
	background: #ffffff;

	.cell:last-child {
		border-bottom: none;
	}

	&:not(:first-child) {
		margin-top: 12px;
	}
	&.border {
		margin-top: 12px;
	}
}