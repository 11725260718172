$black: rgb(30, 30, 30);
$orange: rgb(255, 102, 0);
$brightOrangeNonactive: rgba(255, 102, 0, .5);

$color1: rgb(86, 86, 86); //#565656
$color2: #6f6f6f;
$color3: rgb(142, 142, 142); //#8E8E8E
$color4: rgb(174, 174, 174); //#AEAEAE
$color5: #e7e7e7;
$color6: #f3f3f3;

$light2: rgb(219, 219, 219); // #dbdbdb
$light4: rgb(248, 248, 248); //#F8F8F8
$light5: rgb(250, 250, 250); //#FAFAFA

$warmGrey: rgb(116, 116, 116); //#747474
$white: rgb(237, 237, 237); //#EDEDED
$badgeGrey: rgb(142, 142, 146); //#8E8E92
$lightGrey: rgb(142, 142, 147); //#8E8E93

$regular: rgb(66, 66, 66); //#424242

$wrn1: rgb(225, 44, 26); //#E12C1A
$red: rgb(255, 59, 48); //#FF3B30
$dr1: rgb(255, 118, 0);
$darkRed: rgb(93, 35, 0); //#5D2300
$badgeRed: rgb(201, 0, 0); //#C90000

$blockSpacing: 12px;
$titleBlack: #1e1e1e;
$boxShadow: rgba(0, 0, 0, .12);
$boxBorderRadius: 4px;
$metaTextGrey: #747474;
$descriptionGrey: #494949;
$engagement: #424242;
$bodyText: #1e1e1e;

$separator: 1px solid $light2;

$borderColor: rgb(201, 201, 201);

$fontLight: 100;
$fontMedium: 500;
$fontSemiBold: 600;
$fontBold: 700;
$fontHeavy: 900;
$fontNormal: 400;

// Gaiži zilais
$unseen: rgb(233, 246, 250);

// Pasākuma filtra krāsas
$orange2: rgb(255, 133, 0);
$lightblue: rgb(46, 159, 220);
$darkblue: rgb(78, 85, 150);
$green: rgb(82, 161, 37);
$rosy: rgb(205,71,71);

//pogu krāsas
$primaryBtn: linear-gradient(to bottom, #ff6f0e, #ff6600 75%, #f56100);
$primaryBorder: #f56100;
$primaryShadow:  0 1px 0 0 rgba(22, 29, 37, 0.1), inset 0 1px 0 1px rgba(255, 255, 255, 0.08);

$primaryActive: #e15a00;
$primaryActiveBorder: #b93e00;
$primaryActiveShadow: inset 0 2px 1px 0 #d94700;

$primaryFocusBorder: #c13900;

$primaryDisabled: #ffb482;
$primaryDisabledBorder: #f7a975;




