@charset "UTF-8";
@import './../css/blocks';

$black: #393939;
$ribbon_val_0: #fe8b28;
$ribbon_val_1: #209bdf;
$ribbon_val_2: #5eb223;
$ribbon_val_3: #f4ad13;
$ribbon_val_4: #f72a28;
$ribbon_val_5: #de1ad0;
$ribbon_val_6: #00bab5;
$ribbon_val_7: #f3dd11;
$ribbon_val_8: #c8682c;
$ribbon_val_9: #dd1686;

.giftsMainPage {
	max-width: 667px;
	margin: 0 auto;
	position: relative;
}

.GiftsInfobox {
	position: relative;
	padding: 12px 16px 2px;
	box-sizing: border-box;

	.gradient {
		width: 100%;
		height: 140px;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		z-index: 1;
		background: -moz-radial-gradient(50% 100%, ellipse cover, rgba(125,185,232,0) 0%, rgba(143,198,221,1) 100%);
		background: -webkit-radial-gradient(50% 100%, ellipse cover, rgba(125,185,232,0) 0%,rgba(143,198,221,1) 100%);
		background: radial-gradient(ellipse at 50% 100%, rgba(125,185,232,0) 0%,rgba(143,198,221,1) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#007db9e8', endColorstr='#8fc6dd',GradientType=1 );

		.pattern {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 140px;
			background-image: url(/i/gifts/confetti.png);
			background-image: -webkit-image-set(url(/i/gifts/confetti.png) 1x,url(/i/gifts/confetti@2x.png) 2x,url(/i/gifts/confetti@3x.png) 3x);
			background-image: image-set(url(/i/gifts/confetti.png) 1x,url(/i/gifts/confetti@2x.png) 2x,url(/i/gifts/confetti@3x.png) 3x);
			background-size: 104px;
		}

		.overlay {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 140px;
			background: -moz-linear-gradient(top, rgba(255,255,255,0.5) 0%, rgba(255,255,255,0.51) 1%, rgba(255,255,255,1) 100%);
			background: -webkit-linear-gradient(top, rgba(255,255,255,0.5) 0%,rgba(255,255,255,0.51) 1%,rgba(255,255,255,1) 100%);
			background: linear-gradient(to bottom, rgba(255,255,255,0.5) 0%,rgba(255,255,255,0.51) 1%,rgba(255,255,255,1) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 );
		}
	}

	.close {
		position: absolute;
		background-image: url(/i/icon/icnCloseTyp1Inactive.png);
		background-image: -webkit-image-set(url(/i/icon/icnCloseTyp1Inactive.png) 1x,url(/i/icon/icnCloseTyp1Inactive@2x.png) 2x,url(/i/icon/icnCloseTyp1Inactive@3x.png) 3x);
		background-image: image-set(url(/i/icon/icnCloseTyp1Inactive.png) 1x,url(/i/icon/icnCloseTyp1Inactive@2x.png) 2x,url(/i/icon/icnCloseTyp1Inactive@3x.png) 3x);
		background-size: 24px;
		width: 48px;
		height: 48px;
		right: 0;
		top: 0;
		background-repeat: no-repeat;
		background-position: center;
		z-index: 5;
		opacity: 0.5;

		&:active {
			opacity: 1;
		}
	}

	.infoboxContent {
		box-sizing: border-box;
		border-bottom: 1px solid #eaebeb;
		position: relative;
		z-index: 3;

		.title {
			font-size: 22px;
			line-height: 28px;
			letter-spacing: 0.3px;
			color: #1e1e1f;
			text-align: left;
			font-weight: bold;
			margin-bottom: 12px;
		}

		.description {
			color: #1e1e1f;
			text-align: center;
			letter-spacing: -0.2px;
			font-size: 15px;
			line-height: 20px;
		}

		.items {
			margin: 8px auto 12px;
			text-align: center;

			.item {
				position: relative;
				display: inline-block;
				margin: 0 8px;
				box-sizing: border-box;

				.itemTitle {
					font-size: 13px;
					line-height: 18px;
					height: 18px;
					letter-spacing: -0.1px;
					text-align: center;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					margin-top: 22px;
					font-weight: bold;
					max-width: 125px;
				}

				.shuffle {
					position: absolute;
					top: 106px;
					left: 50%;
					margin-left: -18px;
					width: 36px;
					height: 36px;
					border: 1px solid #eaebeb;
					border-radius: 100%;
					background-color: #fff;
					background-repeat: no-repeat;
					background-position: center center;
					box-sizing: border-box;
					background-image: url(/i/gifts/icnRefreshPrimary.png);
					background-image: -webkit-image-set(url(/i/gifts/icnRefreshPrimary.png) 1x,url(/i/gifts/icnRefreshPrimary@2x.png) 2x,url(/i/gifts/icnRefreshPrimary@3x.png) 3x);
					background-image: image-set(url(/i/gifts/icnRefreshPrimary.png) 1x,url(/i/gifts/icnRefreshPrimary@2x.png) 2x,url(/i/gifts/icnRefreshPrimary@3x.png) 3x);
					background-size: 24px;
					z-index: 3;
				}
			}

			.pillow {
				width: 125px;
				height: 125px;
				background-color: #ffffff;
				background-position: center center;
				background-repeat: no-repeat;
				box-shadow: 0 3px 5px rgba(0,0,0,.16);
				//border: 1px solid #eaebeb;
				border-radius: 22px;
				box-sizing: border-box;
				position: relative;

				&:after {
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					box-shadow: 0 0 1px #eaebeb;
					border-radius: 22px;
				}

				.rqOverlay {
					border-radius: 22px;
				}

				&.gift {
					background-size: 108px 108px;
				}

				&.user {
					background-size: 100%;
				}
			}
		}

		.btnWrapper {
			text-align: center;
			margin-bottom: 16px;

			.btnSend {
				display: inline-block;
				font-size: 15px;
				line-height: 20px;
				letter-spacing: -0.2px;
				color: #f60;
				text-align: center;
				padding: 6px 12px;
				box-sizing: border-box;
				border: 1px solid #f60;
				border-radius: 4px;
				font-weight: 500;

				&:active {
					background: #f60;
					color: #fff;
				}
			}
		}
	}
}

.hairlines {
	.GiftsInfobox .infoboxContent {
		border-width: 0.5px;
	}
}

/* normal scroll listes */
.giftList {
	text-align: center;
	padding: 0;
	padding-top: 15px;

	li {
		list-style: none;
		width: 80px;
		margin: 4px 15px 4px 0;
		min-height: 140px;
		display: inline-block;
		vertical-align: top;
		text-align: left;
		position: relative;

		&:before {
			@include backgroundContain();
			content: '';
			position: absolute;
			top: 0;
			left: 7px;
			width: 80px;
			height: 80px;
			background-image: url(/i/gifts/grey-box-01.svg);
			z-index: 1;
		}

		> * {
			position: relative;
			z-index: 2;
		}

		span.giftTitle {
			color: #232425;
			font-weight: bold;
			display: block;
			max-height: 32px;
			line-height: 16px;
			overflow: hidden;
			text-overflow: ellipsis;
			font-size: 12px;
			white-space: nowrap;
		}
		/* templates list */
		a {
			display: inline-block;
			vertical-align: top;
			width: 80px;
			height: 22px;
			padding: 92px 0 0;
			background-position: center 4px;
			background-repeat: no-repeat;
			background-size: 64px auto;
		}
		/* sūtīta dāvana */
		.cardItem {
			display: inline-block;
			vertical-align: top;
			width: 80px;
			height: 165px;
			margin: 0 15px 0 0;

			.giftImage.sent,
			.giftImage.seen_1 {
				&.hasVideo {
					&:after {
						content: '+ VIDEO';
						display: inline-block;
						position: absolute;
						top: 56px;
						width: 40px;
						text-align: center;
						border: 1px solid silver;
						background: white;
						padding: 1px;
						line-height: 10px;
						color: gray;
						text-transform: uppercase;
						font-size: 8px;
						left: 50%;
						margin-left: -20px;
						border-radius: 2px;
					}
				}
				&.hasPic {
					&:after {
						content: '+ FOTO';
						display: inline-block;
						position: absolute;
						top: 56px;
						width: 40px;
						text-align: center;
						border: 1px solid silver;
						background: white;
						padding: 1px;
						line-height: 10px;
						color: gray;
						text-transform: uppercase;
						font-size: 8px;
						left: 50%;
						margin-left: -20px;
						border-radius: 2px;
					}
				}

			}
			.giftImage {
				margin-bottom: 8px;
				padding-top: 4px;
				text-align: center;
				.image {
					width: 64px;
					height: 64px;
					background-repeat: no-repeat;
					background-position: center;
					background-size: contain;
					top: 8px;
					margin: 0 auto 20px;
				}
			}
			.senderTitle {
				font-weight: bolder;
			}
			.senderTitle,
			.receiverTitle {
				display: block;
				opacity: .9;
				height: 16px;
				line-height: 16px;
				text-overflow: ellipsis;
				font-size: 12px;
				overflow: hidden;
				white-space: nowrap;
				color: #5c5e60;
				font-weight: normal;
			}
			.created {
				font-size: 12px;
				line-height: 16px;
				color: #8d8d8d;
				display: block;
			}
			&.new {
				.giftTitle {
					display: none;
				}
				.senderTitle {
					font-weight: bold;
					color: #ff6600;
				}
			}
		}
	}
}

.received .longList .giftList li:before {
	top: 12px;
	left: 10px;
	width: 60px;
	height: 60px;
}

/* sākumlapas bloki */
.giftsBlock {
	&.news {
		background: #e3f4f6 url(/i/gifts/jdavanas_defaultbg@2x.png);
		background-size: 320px auto;
		position: relative;
		&:before {
			background-image: url(/i/gifts/davanu_jaunumi@2x.png);
			background-size: 100% auto;
			max-width: 209px;
			background-repeat: no-repeat;
			background-position: center;
			content: ' ';
			margin: 0 auto;
			display: block;
			height: 50px;
			width: 100%;
		}
		.giftsBlockHeader {
			display: none;
		}
	}
	&.exclusive {
		margin-top: -5px;
		&:first-child {
			margin-top: 0;
		}
		background: rgba(249, 238, 208, 1) url(/i/gifts/confetti_gold.png) repeat-x;
		//background-image: url(/i/gifts/confetti_gold.png);
		background-image: -webkit-image-set(
				url(/i/gifts/confetti_gold.png) 1x,
				url(/i/gifts/confetti_gold_2x.png) 2x
		);
		background-image: image-set(
				url(/i/gifts/confetti_gold.png) 1x,
				url(/i/gifts/confetti_gold_2x.png) 2x
		);
		position: relative;
		&:before {
			content: '';
			width: 100%;
			height: 100%;
			position: absolute;
			background: linear-gradient(to bottom, rgba(249, 238, 208, 0.2), rgba(249, 238, 208, 1));
		}

		.heading {
			h2 {
				color: #232425;
				font-size: 15px;
				font-weight: bold;
			}
			.goRight {
				top: 12px;
			}
		}

		.exclusiveDescription {
			position: relative;
			font-size: 12px;
			line-height: 17px;
			color: #232425;
			opacity: .6;
			margin: 0 8px 10px;
		}

		&.single {
			.giftList {
				li {
					background-position: left top;
					a {
						height: 92px;
						padding: 0 0 0 92px;
						background-position: left 4px;

						.giftDescription {
							display: block;
							font-size: 13px;
							color: #1e1e1e;
							letter-spacing: -0.1pt;
							line-height: 18px;
							width: 200px;
						}
					}
				}
			}
			.scrollClip {
				margin-left: 10px;
			}
		}
	}
	&.valentine {
		background: #ff8bc1 url(/i/gifts/davanas_bg_valentine@2x.png);
		&:before {
			background-image: url(/i/gifts/valentine_jaunumi_title@2x.png);
		}
	}
	&.valentineDay {
		background: #ff8bc1 url(/i/gifts/bg_valentineDay.jpg);
		&:before {
			background-image: url(/i/gifts/apber.png);
			max-width: 360px;
		}
	}
	.heading {
		position: relative;
		height: 22px;
		line-height: 20px;
		margin: 0 12px 10px;
		padding-top: 6px;
		h2 {
			background: none;
			border: 0;
			margin: auto;
			color: $black;
			font-size: 16px;
			font-weight: normal;
		}
		.goRight {
			position: absolute;
			top: 10px;
			right: 0;
			color: #6f6f6f;
			line-height: 16px;
			&:after {
				content: ' ';
				display: inline-block;
				vertical-align: middle;
				width: 12px;
				height: 12px;
				background: url(//ifrype.com/i/icons/sprite/icn_a1right_2x.png) no-repeat center -12px;
				background-size: 12px auto;
				margin-left: 6px;
			}
		}
	}
	/* hor scroll listes */
	&.sent .scrollClip {
		border-bottom: 0;
	}
	.scrollClip {
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);
		max-width: 100%;
		margin-bottom: 5px;
		margin-left: 5px;
		overflow: hidden;
		overflow-x: scroll;
		overflow-scrolling: touch;
		-webkit-overflow-scrolling: touch;
		z-index: 0;
		.giftList {
			margin: 5px 0;
			li {
				-webkit-transform: translate3d(0, 0, 0);
				display: table-cell;
				vertical-align: top;
				max-width: 80px;
				margin: 0;
				padding: 0 7px;
			}
		}
	}
}

/* ANIMĒTĀS */
/*animētās*/

@for $i from 3 through 137 {
	@keyframes playGift#{$i} {
		100% {
			background-position: -100%*$i
		}
	}
	.giftImage .image.clip.steps#{$i},
	.giftImage .img.clip.steps#{$i} {
		animation: playGift#{$i} 0.05s*$i steps($i, end) infinite;
	}
}

.giftImage .image.clip,
.giftImage .img.clip {
	background-size: auto 100% !important;
	background-position: left center !important;
	background-repeat: repeat-x !important
}

/* COLORS */
.cardList .giftList li .cardItem {
	.giftImage.seen_0.received {
		position: relative;
		&:before,
		&:after {
			display: block;
			background: url(//ifrype.com/gifts/img/colors/diz1_flatwrap_2x.png) no-repeat center;
			background-size: contain;
			width: 80px;
			height: 80px;
			position: absolute;
			left: 50%;
			margin-left: -40px;
			top: 0;
			border-radius: 10px;
			content: ' ';
		}
		&:after {
			background: url(//ifrype.com/gifts/img/colors/krs4_bante_flatwrap_2x.png) no-repeat center;
			background-size: contain;
		}
		@for $i from 0 through 39 {
			&.box_#{$i}:before {
				background-image: url("//ifrype.com/gifts/img/colors/diz#{$i + 1}_flatwrap_2x.png");
			}
		}
		@for $i from 100 through 102 {
			&.box_#{$i}:before {
				background-image: url("//ifrype.com/gifts/img/colors/diz#{$i}_flatwrap_2x.png");
			}
		}
		@for $i from 0 through 19 {
			&.ribbon_#{$i}:after {
				background-image: url("//ifrype.com/gifts/img/colors/krs#{$i + 1}_bante_flatwrap_2x.png");
			}
		}
	}
}

.cardBox {
	.box {
		.ribbon {
			@for $i from 0 through 19 {
				&.val_#{$i} {
					background-image: url("//ifrype.com/gifts/img/colors/comp_krs#{$i + 1}_bante_2x.png");
				}
			}
		}
		.container {
			@for $i from 0 through 39 {
				&.val_#{$i} {
					background-image: url("//ifrype.com/gifts/img/colors/comp_diz#{$i + 1}_box_uo_2x.png?3");
				}
			}
		}
	}
}

/* dāvanu popaps lielā kaste */
.giftCardPreview.firstpagePop {
	.cardBox {
		.box {
			/* default */
			.cover {
				background-image: url(//ifrype.com/gifts/img/colors/diz1_vaks_2x.png)
			}
			.container {
				background-image: url(//ifrype.com/gifts/img/colors/diz1_aizmugure_2x.png)
			}
			.container:before {
				background-image: url(//ifrype.com/gifts/img/colors/diz1_prieksa_2x.png)
			}
			.cover:before {
				background-image: url(//ifrype.com/gifts/img/colors/krs1_bante_2x.png)
			}

			@for $i from 0 through 39 {
				&.box_#{$i} {
					.cover {
						background-image: url("//ifrype.com/gifts/img/colors/diz#{$i + 1}_vaks_2x.png")
					}
					.container {
						background-image: url("//ifrype.com/gifts/img/colors/diz#{$i + 1}_aizmugure_2x.png")
					}
					.container:before {
						background-image: url("//ifrype.com/gifts/img/colors/diz#{$i + 1}_prieksa_2x.png")
					}
				}
			}
			@for $i from 100 through 102 {
				&.box_#{$i} {
					.cover {
						background-image: url("//ifrype.com/gifts/img/colors/diz#{$i}_vaks_2x.png")
					}
					.container {
						background-image: url("//ifrype.com/gifts/img/colors/diz#{$i}_aizmugure_2x.png")
					}
					.container:before {
						background-image: url("//ifrype.com/gifts/img/colors/diz#{$i}_prieksa_2x.png")
					}
				}
			}

			@for $i from 0 through 19 {
				&.ribbon_#{$i} .cover:before {
					background-image: url("//ifrype.com/gifts/img/colors/krs#{$i + 1}_bante_2x.png");
				}
			}

			&.ribbon_100 .cover:before {
				background-image: url(//ifrype.com/gifts/img/colors/krs100_bante_2x.png)
			}
		}
	}
}

.giftsCatPage {
	li:before {
		left: 0;
	}
}

/* list scroll lsites ar non-standart last-minute color izmēriem n such */
.longList {
	.giftList {
		li {
			float: none;
			display: block;
			height: 85px;
			min-height: 85px;
			padding: 10px 12px;
			background-position: left center;
			background-size: 64px 64px;
			width: 100%;
			clear: both;
			border-bottom: 1px solid #f0f0f0;
			box-sizing: border-box;

			&:before {
				top: 12px;
				left: 0;
				width: 60px;
				height: 60px;
			}

			.cardItem {
				height: 64px;
				width: 100%;
				display: block;
				.giftImage.sent,
				.giftImage.seen_1 {
					&.hasVideo {
						&:after {
							left: 42px;
							top: 40px;
						}
					}
					&.hasPic {
						&:after {
							left: 42px;
							top: 40px;
						}
					}

				}
				.giftImage {
					display: block;
					float: left;
					width: 64px;
					margin-right: 12px;
					position: relative;
					&:before,
					&:after {
						max-width: 64px;
						max-height: 64px;
						margin-left: -32px !important; //XXXXXXXXXXXX!!!
					}
					.image {
						margin: 4px;
						width: 48px;
						height: 48px;
					}
				}
				.giftTitle {
					font-size: 16px;
					line-height: 20px;
					font-weight: bolder;
					margin-bottom: 4px;
				}
				.senderTitle,
				.receiverTitle {
					font-size: 14px;
					line-height: 15px;
					margin-bottom: 6px;
				}
				.created {
					font-size: 13px;
					line-height: 16px;
				}
			}
		}
	}
}

.giftForm {
  	.inputRange {
	  	width: 90%;
	}
  	.rangeVal{
	  	margin: 0 5px;
		p {
			color: $red;
			font-size: 12px;
		}
	}
	.inlineCurrencyInput {
		width: 80px;
	}
	.colorSwitcher {
		&.boxColors {
			.pickItem {
				@for $i from 0 through 39 {
					&.val_#{$i} {
						background-image: url("//ifrype.com/gifts/img/colors/diz#{$i + 1}_thumb_2x.png")
					}
				}
			}
		}
		&.ribbonColors {
			.pickItem {
				@for $i from 0 through 19 {
					&.val_#{$i} {
						background: nth((
							$ribbon_val_0,
							$ribbon_val_1,
							$ribbon_val_2,
							$ribbon_val_3,
							$ribbon_val_4,
							$ribbon_val_5,
							$ribbon_val_6,
							$ribbon_val_7,
							$ribbon_val_8,
							$ribbon_val_9,
							url('//ifrype.com/gifts/img/colors/krs11_pickItem_val.png'),
							url('//ifrype.com/gifts/img/colors/krs12_pickItem_val.png'),
							url('//ifrype.com/gifts/img/colors/krs13_pickItem_val.png'),
							url('//ifrype.com/gifts/img/colors/krs14_pickItem_val.png'),
							url('//ifrype.com/gifts/img/colors/krs15_pickItem_val.png'),
							url('//ifrype.com/gifts/img/colors/krs16_pickItem_val.png'),
							url('//ifrype.com/gifts/img/colors/krs17_pickItem_val.png'),
							url('//ifrype.com/gifts/img/colors/krs18_pickItem_val.png'),
							url('//ifrype.com/gifts/img/colors/krs19_pickItem_val.png'),
							url('//ifrype.com/gifts/img/colors/krs20_pickItem_val.png')
						), $i + 1);
						background-size: cover;
					}
				}
			}
		}
		> .colorSwitcher__paginator {
			$max-dots: 5;
			&:empty ,
			&:has(i:only-child),
			&:has(i:nth-child(#{$max-dots + 1})) {
				display: none;
			}
			display: flex;
			justify-content: center;
			align-items: center;
			margin-bottom: 10px;
			> i {
				$dot: 6px;
				width: $dot;
				height: $dot;
				border-radius: $dot;
				margin: 0 6px;
				background: #c9c9c9;
			}
			@for $i from 0 through $max-dots - 1 {
				&[data-selected-item='#{$i}'] {
					i:nth-child(#{$i+1}) {
						background: #1e1e1e;
					}
				}
			}
		}
	}
}

#contentW.faded {
	background: #303030;
}

.giftCardPreview.firstpagePop {
	.todo.previewFooter {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		background: white;
		height: 40px;
		border-top: 1px solid rgba(0, 0, 0, 0.08);
	}
	.cardPaper {
		-webkit-transform: translateZ(0);
		transition: all .3s ease;
		transform: translateY(0);
		opacity: 1;
	}
	&.justOpened {
		.cardPaper {
			transform: translateY(200px);
			opacity: 0;
		}
	}
	.cardBox {
		height: 394px;
		.box {
			height: 288px;
			width: 288px;
			margin: 0 auto;
			position: relative;
			.cover:before,
			.container:before,
			.cover,
			.container {
				content: ' ';
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				height: 288px;
				width: 288px;
				background-size: 288px auto;
			}
			.cover {
				transition: all 1s ease-out .4s;
			}
			.container {
				transition: all .5s ease-out .5s;
			}
			&.opened {
				.cover {
					transform: translateY(-300px) rotate(25deg);
				}
				.container {
					transform: translateY(10px) rotate(5deg);
				}
			}
		}
	}
}

.popNav {
	.navLabel {
		top: 15px;
		width: 100%;
		text-align: center;
		color: silver;
		position: absolute;
	}
	.navBtn {
		transition: opacity .1s ease-in;
		position: absolute;
		display: inline-block;
		height: 32px;
		width: 32px;
		background: url('//ifrype.com/gallery/img/fs/3/icn_arrow_right_3.png') no-repeat center center;
		background-size: 32px auto;
		opacity: .6;
		top: 50%;
		&:not(.disabled):hover {
			opacity: 1;
		}
		&.disabled {
			opacity: .1;
			cursor: default;
		}
		&.nextBtn {
			right: 12px;
			background-image: url('//ifrype.com/gallery/img/fs/3/icn_arrow_right_3.png');
		}
		&.prevBtn {
			left: 12px;
			background-image: url('//ifrype.com/gallery/img/fs/3/icn_arrow_left_3.png');
		}
	}
}

.exclusive .verticalPreview .giftPreview {
	background: transparent;
}

.verticalPreview .giftPreview {
	background: #f0f0f0 url(//ifrype.com/gifts/img/colors/davanas_kfons_0.png) no-repeat top center;
	background-size: cover;
	width: 100%;
	//height: $cardPopHeight;
	padding: 40px 0;
	position: relative;
	/* card */
	.infoCaption {
		line-height: 40px;
		color: gray;
		text-align: center;
		white-space: nowrap;
		display: block;
		position: absolute;
		top: 0;
		width: 100%;
	}
	.cardPaper {
		background: white;
		border-radius: 10px;
		//border: 3px solid white;
		box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3)/*, inset 0 0 1px rgba(0, 0, 0, 0.3)*/;
		margin: 0 auto;
		text-align: left;
		position: relative;
		min-height: 320px;
		padding: 24px;
		width: 250px;
		.giftPicture {
			width: 220px;
			height: 140px;
			margin: 0 auto 10px;
			.giftImage {
				transition: all .1s ease-in;
				margin: 0 auto;
				text-align: center;
				width: 140px;
				height: 140px;
				border: 2px solid rgba(0, 0, 0, 0.08);
				background: #f9f9f9;
				border-radius: 50%;
				.image {
					background-size: 100% auto;
					width: 150px;
					height: 150px;
					margin: -5px;
				}
			}
			&.giftWithMedia {
				text-align: center;
				position: relative;
				.giftImage {
					border: 2px solid rgba(0, 0, 0, 0.16);
					width: 70px;
					height: 70px;
					position: absolute;
					bottom: -10px;
					right: 0;
					z-index: 2;
					.image {
						background-size: 100% auto;
						width: 90px;
						height: 90px;
						margin: -10px;
					}
				}
				.mediaPreview {
					margin: 0 auto;
					max-width: 186px;
					height: 140px;
					border-radius: 5px;
					background-size: cover;
					background-position: center;
					box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
					position: relative;
					&.video:before {
						content: " ";
						display: block;
						width: 50px;
						height: 50px;
						background: rgba(0, 0, 0, 0.6) url(//ifrype.com/gifts/img/icn_play.png) no-repeat center;
						border-radius: 50%;
						position: absolute;
						top: 50px;
						left: 50%;
						margin-left: -20px;
					}
					.closeIcon {
						position: absolute;
						top: -14px;
						right: -14px;
						background: url(/i/ad_close.png) no-repeat center;
						background-size: 100% 100%;
						width: 36px;
						height: 36px;
						display: block;
						padding: 0;
						box-shadow: 0 0 0 2px rgba(255, 255, 255, .9);
						border-radius: 50%;
					}
				}
			}
		}
		.texts {
			word-wrap: break-word;
			margin-bottom: 60px;
			.previewTitle {
				font-size: 16px;
				line-height: 1.4em;
				font-weight: bold;
				margin-bottom: 6px;
			}
			.previewText {
				white-space: pre-wrap;
			}
		}
		.weatherInfo {
			color: rgb(111, 111, 111);
			font-size: 12px;
			margin-bottom: 50px;
			padding-top: 12px;
			border-top: 1px solid rgb(242, 243, 244);
		}
		.senderPreview {
			position: absolute;
			bottom: 0;
			margin: 15px 0;
			width: 100%;
			text-decoration: none;
			color: black;
			img {
				float: left;
				border-radius: 50%;
				margin-right: 10px;
				width: 35px;
				height: 35px;
			}
			span {
				display: none;
			}
			b {
				line-height: 35px;
			}
		}
	}
	/* box */
	.cardBox {
		text-align: center;
		padding: 30px 20px 0;
		.recipientCaption {
			color: black;
			display: block;
			margin-bottom: 6px;
		}
		.recipient {
			font-size: 14px;
			line-height: 1.4em;
			font-weight: bold;
			display: block;
			margin: 0 40px;
			.rec {
				& + .rec {
					&:before {
						content: ', ';
					}
				}
			}
		}
		.previewText {
			white-space: pre-wrap;
		}
		.previewTitle {
			font-size: 16px;
			line-height: 1.4em;
			font-weight: bold;
			margin-bottom: 6px;
		}
		.senderPreview {
			margin-top: 10px;
			img {
				border-radius: 50%;
				width: 35px;
				height: 35px;
			}
			b {
				display: block;
				font-weight: normal;
			}
		}
		.OFFbox {
			width: 216px;
			height: 216px;
			margin: 6px auto;
			position: relative;
			.ribbon {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 216px;
				margin: 0 auto;
				background: url(//ifrype.com/gifts/img/colors/comp_krs1_bante_2x.png) no-repeat center;
				background-size: 216px auto;
				z-index: 2;
			}
			.container {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 216px;
				background-position: center;
				background-size: 216px auto;
			}
		}
	}
	.giftCtas {
		text-align: center;
		a {
			display: inline-block;
			margin: 12px 0;
			+ a {
				margin-left: 12px;
			}
			&.inactive {
				opacity: .8;
				cursor: default;
				text-decoration: none;
			}
		}
		.wearSkinIcon,
		.removeIcon {
			display: none;
		}
	}
	/*jub*/
	&.ribbon_100 {
		.giftPicture {
			display: none;
		}
		.cardPaper {
			background: url(/i/gifts/drsveic_bg@2x.png) no-repeat center;
			background-size: cover;
			text-align: center;
			font: bold 16px/20px Helvetica, Arial, sans-serif;
			color: white;
			text-shadow: 1px 1px 1px rgba(79, 1, 3, .32);
			padding: 24px 0;
			width: 296px;
			.ylo {
				color: #fff2ca;
			}
			.ln1 {
				font-size: 16px;
				line-height: 20px;
				margin: 38px 0 28px;
			}
			.numLn {
				font-size: 32px;
				line-height: 30px;
				white-space: nowrap;
				b {
					font-size: 44px;
					line-height: 30px;
					padding-right: 12px;
				}
			}
			.roundJoint {
				background: url(/i/gifts/drsveic_jeb@2x.png) no-repeat center;
				background-size: 28px auto;
				height: 28px;
				margin: 14px auto 12px;
			}
			.ln2 {
				font-size: 14px;
				line-height: 18px;
				margin-top: 28px;
			}
			.ln3 {
				font-size: 16px;
				line-height: 36px;
			}
			.ln4 {
				font-size: 18px;
				line-height: 24px;
			}
			.senderPreview {
				left: 24px;
				text-align: left;
				color: white;
				font-size: 13px;
				line-height: 17px;
				text-shadow: none;
				img {
					box-shadow: 0 0 2px rgba(0, 0, 0, 0.08);
				}
			}
		}
	}
	$excl: (19056, 18939, 18866, 18820,18744,18743, 18639, 18613,18537,18496,18484,18448,18437,18406,18330,17836,17643,17546,18127,18106,17884,17562,18078,18716,18743);
	@each $id in $excl {
		&.gift_#{$id} .cardPaper {
			background: rgba(255, 255, 255, 0.5);

			.giftPicture .giftImage {
				background: none;
				box-shadow: none;
				border-color: transparent;
			}
		}
	}
}

/* SŪTĪŠANAS FORMA */
.giftForm {
	background: #252b33;
  	min-height: 100vh;
	.buttonWrap {
		margin-top: 4px;
		button {
			height: 44px;
			line-height: 44px;
		}
	}

	.paneBox {
		padding: 15% 0;
		max-width: 450px;
		margin: 0 auto;
		position: relative;

		.pane {
			max-width: 450px;
			margin: 0 8% 15px;
			background: white;
			border-radius: 10px;
			box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3), inset 0 0 1px rgba(0, 0, 0, 0.3);
			//border: 3px solid white;
			&.boxPane {
				padding: 0 0 10px;
				border: 0;
				.giftPreview {
					background: url(//ifrype.com/gifts/img/colors/davanas_kfons_0.png) no-repeat top;
					background-size: 100% auto;
					border-radius: 10px 10px 0 0;
				}
				.colorSwitcher,
				.bottomBox {
					margin: 0 10px;
				}
			}
			&.cardPane {
			  	min-height: 200px;
				padding: 10px;
			}
			&.closed {
				display: none;
			}

		  	.requirePin {
			  	width: 80%;
			  	margin: 10px auto 20px;

			  	div {
				  	margin-bottom: 5px;
				}
			}
		}
	}
	label {
		margin: 0 5px;
		padding: 5px 0;
		color: gray;
	}
	.inputWrap textarea {
		min-height: 50px;
		height: auto;
	}
	.formTextareaInfo {
		font-size: 11px;
		color: gray;
	}
	.colorSwitcher {
		margin: 0 -2px;
		padding: 0;
		.pickItem {
			cursor: pointer;
			background: #f0f0f0;
			background-size: cover;
			background-position: center;
			display: inline-block;
			margin: 2px 1% 4px;
			height: 15vw;
			max-height: 65px;
			width: 18%;
			border-radius: 4px;
			border: 2px solid white;
			box-sizing: border-box;
			&.selected {
				box-shadow: 0 0 0 2px #ff6600;
			}
		}
	}
	.titleInput input {
		font-size: 1.2em;
		height: 1.2em;
		font-weight: bold;
	}
	.box {
		width: 216px;
		height: 216px;
		margin: 6px auto;
		position: relative;
		.ribbon {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 216px;
			margin: 0 auto;
			background: url(//ifrype.com/gifts/img/colors/comp_krs1_bante_2x.png) no-repeat center;
			background-size: 216px auto;
			z-index: 2;
		}
		.container {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 216px;
			background-position: center;
			background-size: 216px auto;
		}
	}
	.previewGiftNode {
		position: relative;
		.addMedia {
			position: absolute;
			width: 40px;
			top: 52px;
			height: 50px;
			right: 0;
			z-index: 1;
			.icon {
				padding: 0;
			}
			a {
				display: block;
				text-indent: -9999px;
				width: 28px;
				height: 28px;
				border-radius: 50%;
				margin-bottom: 20px;
				background-size: 28px 28px;
				background-repeat: no-repeat;
				background-position: center;
				&.videoIcon {
					background-image: url('/i/icon/icn_dav_video_2x.png');
				}
				&.photoIcon {
					background-image: url('/i/icon/icn_dav_foto_2x.png');
				}
			}
		}
	}
	.giftPreview {
		.cardBox {
			padding-top: 10px;
			text-align: center;
			.recipientCaption {
				color: gray;
				display: block;
				margin-bottom: 4px;
			}
			.recipient {
				font-size: 14px;
				line-height: 1.4em;
				font-weight: bold;
				display: block;
				margin: 0 40px;
				.rec {
					& + .rec {
						&:before {
							content: ', ';
						}
					}
				}
			}
			.previewTitle,
			.senderPreview {
				display: none;
			}
		}
	}
	.giftCardPreview {
		.infoCaption,
		.senderPreview,
		.texts {
			display: none;
		}
		.giftPreview {
			background: white;
			padding: 20px 0;
		}
		.cardPaper {
			height: auto;
			min-height: 0;
			background: transparent;
			border: none;
			box-shadow: none;
			padding: 0;
			margin: 0 auto;
		}
	}
}

.sentOk {
	display: block;
	padding: 30vh 0 0;
	text-align: center;
	background: url(/i/gifts/icn_successmsg@2x.png) no-repeat center;
	background-size: 42px auto;
}

/* yt picker */
.ytSearchResults {
	margin-top: 4px;
	border-top: 1px solid #eeeeee;
	padding-top: 4px;
	.resultsItem {
		border-bottom: 1px solid #eeeeee;
		padding-bottom: 5px;
		margin-bottom: 5px;
		min-height: 50px;
		.videoThumb {
			height: 50px;
			margin-right: 10px;
			float: left;
		}
		.videoTitle {
			font-weight: normal;
			font-size: 11px;
		}
		.sourceTitle {
			display: none;
		}
	}
	.lilMessage {
		background: #eeeeee;
		color: gray;
		font-size: 11px;
		text-align: center;
		padding: 10px;
	}
}

/* upl button */
.imagePickerBtn.button {
	overflow: hidden;
	position: relative;
	margin: 5vh 2%;
	padding: 0;
	.waiterTag {
		position: absolute;
		color: white;
		top: 3px;
		left: 3px;
	}
	.buttonWrap button {
		height: 40px;
		line-height: 40px;
	}
	.Upload {
		position: absolute;
		top: 0;
		border: 0;
	}
}

.imagePickerContent {
	position: absolute;
	width: 100%;
}

.giftsUnseenGifts {
	max-width: unset;

	.unseenGiftsList {
		padding: 16px;
		overflow: scroll;
		scroll-snap-type: x mandatory;
		scroll-padding: 16px;
		white-space: nowrap;
		height: calc(100vh - 48px - 60px);
		box-sizing: border-box;

		.giftCardPreview {
			display: inline-block;
			height: 100%;
			padding: 0 8px;
			text-align: center;
			white-space: normal;
			width: 100%;
			box-sizing: border-box;

			&:only-child {
				width: 100%;
				text-align: center;
				margin: 0;
			}

			/* ----------------------------------------------
			 * Generated by Animista on 2024-5-17 13:38:29
			 * Licensed under FreeBSD License.
			 * See http://animista.net/license for more info.
			 * w: http://animista.net, t: @cssanimista
			 * ---------------------------------------------- */

			/**
			 * ----------------------------------------
			 * animation jello-horizontal
			 * ----------------------------------------
			 */
			@keyframes jello-horizontal {
				0% {
					transform: scale3d(1, 1, 1);
				}
				30% {
					transform: scale3d(1.25, 0.75, 1);
				}
				40% {
					transform: scale3d(0.75, 1.25, 1);
				}
				50% {
					transform: scale3d(1.15, 0.85, 1);
				}
				65% {
					transform: scale3d(0.95, 1.05, 1);
				}
				75% {
					transform: scale3d(1.05, 0.95, 1);
				}
				100% {
					transform: scale3d(1, 1, 1);
				}
			}
			/**
			 * ----------------------------------------
			 * animation fade-in
			 * ----------------------------------------
			 */
			@keyframes fade-in {
				0% {
					opacity: 0;
				}
				100% {
					opacity: 1;
				}
			}


			&.active {
				.cardBox {
					.box {
						animation: jello-horizontal 0.8s ease-in-out both;
					}
				}
			}

			.cta {
				position: absolute;
				bottom: 83px;
				left: 0;
				right: 0;
				padding: 16px;
				animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
			}

			.giftPreview {
				margin: 0 auto;
				border-radius: 6px;
				overflow: hidden;
				padding: 16px;
				box-sizing: border-box;
				display: flex;
				flex-direction: column;
				scroll-snap-align: center;
				height: 100%;

				.cardBox {
					display: flex;
					flex-direction: column;
					padding: 0;
					justify-content: center;
					height: 100%;
					flex-grow: 1;

					> div:first-child {
						flex-grow: 1;
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;
					}

					.senderPreview {
						b {
							font-weight: bold;
						}
					}
				}
			}
		}
	}

	.footer {
		height: 60px;
		color: #fff;
		line-height: 60px;
		text-align: center;
		display: flex;
		justify-content: space-between;

		a, span {
			color: $lightGrey;
			padding: 0 16px;


			&.active {
				color: $white;
			}
		}
	}
}